import React from 'react';
import { useTranslation } from 'react-i18next';

import Article from '@/components/Article';

interface ArticlesProps {
  articles: any;
  title: string;
  withBgImage: boolean;
}

const Articles: React.FC<ArticlesProps> = ({
  articles,
  title,
  withBgImage = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2
        className={`${
          withBgImage ? `text-white` : `text-black`
        } lg:mb-20 mb-12`}
      >
        {title}
      </h2>

      <div className="flex lg:flex-row flex-col justify-between lg:max-w-[100%] m-auto lg:mb-10 mb-6">
        {articles.map((article) => (
          <Article
            key={article.title.rendered}
            title={`${article.title.rendered.substring(0, 43)}${
              article.title.rendered.length > 43 ? ` …` : ``
            }`}
            content={`${article.content.rendered
              .replace(/<[^>]*>/g, ``)
              .substring(0, 100)} …`}
            image={article.yoast_head_json.og_image[0].url}
            link={article.link}
          />
        ))}
      </div>

      <div className="flex flex-row justify-center">
        <a
          href="https://blog.lafraise.app/"
          className="bg-white py-4 px-6 rounded-full text-lg font-black custom-shadow pointer"
        >
          {t(`moreArticles`)} &gt;
        </a>
      </div>
    </>
  );
};

export default Articles;
