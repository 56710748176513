import React from 'react';
import He from 'he';

interface ArticleProps {
  title: string;
  content: string;
  image: string;
  link: string;
}

const Article = ({
  title,
  content,
  image,
  link,
}: ArticleProps): JSX.Element => (
  <a href={link} className="mb-8 lg:mb-0">
    <div className="lg:max-w-[275px] h-[475px] flex flex-col justify-between rounded-3xl custom-shadow bg-white overflow-hidden pointer">
      <div className="h-full flex flex-col justify-between pb-7">
        <div>
          <div
            className="w-full h-44 bg-cover bg-center"
            style={{ backgroundImage: `url(${image})` }}
          />

          <div className="overflow-hidden px-5 pt-7">
            <h3 className="mb-2 break-all">{He.decode(title)}</h3>
            <p>{He.decode(content)}</p>
          </div>
        </div>

        <p className="bg-black text-white py-3 px-6 w-fit rounded-full ml-5">
          Lire l&apos;article
        </p>
      </div>
    </div>
  </a>
);

export default Article;
